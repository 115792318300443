@charset 'UTF-8';

// footer
// ------

footer {
  background: transparent;
  flex: none;
  padding-top: map-unit( $footer-padding-top, sm );
  width: 100%;
  z-index: map-unit( $z-index, footer );
  @include media(md) {
    padding-top: map-unit( $footer-padding-top, md );
  }
  @include media(lg) {
    padding-top: map-unit( $footer-padding-top, lg );
  }
}

.footer-wrap {
  background: $background-footer;
  border-top: 1px solid $gray-200;
  position: relative;
  padding: 0 map-unit( $footer-container-padding, sm );
  @include media(md) {
    padding: 0 map-unit( $footer-container-padding, md );
  }
  @include media(lg) {
    padding: 0 map-unit( $footer-container-padding, lg );
  }
  @include widthWrapper( $footer-container-width, $footer-container-padding );
}

.footer-container {
  margin: 0 auto;
  @include widthContainer( $footer-container-width );
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .6em 0;

  .footer-inner-left {

    .footer-title {
      font-size: .8em;
      font-weight: bold;
      a {
        color: $white-color;
      }
    }
  }

  .footer-inner-right {
    font-size: 1em;
    @include media(md) {
      font-size: 1em;
    }
    @include media(lg) {
      font-size: 1em;
    }
  }
}

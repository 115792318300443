@charset 'UTF-8';

// Copyright
// ---------
.copyright-wrapper {
  background: $background-copyright;
  flex: none;
  padding: .6em map-unit( $copyright-container-padding, sm );
  position: relative;
  width: 100%;
  z-index: map-unit( $z-index, copyright );
  @include media(md) {
    padding: .6em map-unit( $copyright-container-padding, md );
  }
  @include media(lg) {
    padding: .6em map-unit( $copyright-container-padding, lg );
  }
  @include widthWrapper( $copyright-container-width, $copyright-container-padding );
}

.copyright-container {
  margin: 0 auto;
  @include widthContainer( $copyright-container-width );
}

.copyright {
  color: $white-color;
  display: flex;
  align-items: center;
  font-size: 80%;
  text-align: center;
  span {
    display: block;
  }
  img {
    display: block;
    margin-left: 4px;
    width: auto;
    height: 12px;
  }
  @include media(md) {
    font-size: 80%;
    img {
      height: 12px;
    }
  }
  @include media(lg) {
    font-size: 80%;
    img {
      height: 12px;
    }
  }
}

@charset 'UTF-8';

// wrapper
// -------
.global-navigation {
  position: relative;
}

// navigation
// ----------
nav {
  background: $white-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc( 100vh - #{map-get( $height-header, sm ) + px} );
  transform: translateY(-100%);
  transition: transform .3s;
  overflow: auto;
  @include media(md) {
    height: calc( 100vh - #{map-get( $height-header, md ) + px} );
  }
  @include media(lg) {
    height: calc( 100vh - #{map-get( $height-header, lg ) + px} );
  }
  //
  #{$burger-open-class} & {
    top: map-unit( $height-header, sm, rem );
    transform: translateY(0);
    @include media(md) {
      top: map($height-header, md, rem);
    }
    @include media(lg) {
      top: map($height-header, lg, rem);
    }
  }
}

.main_drawer_menu {
  display: flex;
  flex-flow: row wrap;
  padding-top: 2em;
  li {
    flex: 0 0 auto;
    width: 100%;
    padding: .5em 1em;
    @include media(md) {
      flex: 0 0 50%;
      width: 50%;
    }
    a {
      border: 1px solid $gray-200;
      color: $base-color;
      display: block;
      padding: 12px 8px;
      text-align: center;
      overflow: hidden;
      position: relative;
      transition: transform $transition;
      &:before {
        content: '';
        background: $base-color;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: transform $transition;
        transform: translateX(-101%);
        z-index: -1;
      }
      @include media(lg) {
        font-size: 14px;
      }
      &:hover {
        color: $white-color;
        &:before {
          transform: translateX(0%);
        }
      }
    }
  }
}

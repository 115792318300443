@charset 'UTF-8';

// @アニメーション
// -----------
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@keyframes transY {
  0% {
      margin-top: -16px;
  }
  100% {
      margin-top: 0;
  }
}

@keyframes sizeScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes colMove {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@charset 'UTF-8';

// header
// ------

header {
  background: transparent;
  padding-bottom: map-unit( $header-padding-bottom, sm );
  width: 100%;
  z-index: map-unit($z-index, header);
  @include media(md) {
    padding-bottom: map-unit( $header-padding-bottom, md );
  }
  @include media(lg) {
    padding-bottom: map-unit( $header-padding-bottom, lg );
  }
}

.header-wrap {
  background: $background-header;
  border-bottom: 1px solid $gray-200;
  position: relative;
  padding: 0 map-unit( $header-container-padding, sm );
  @include media(md) {
    padding: 0 map-unit( $header-container-padding, md );
  }
  @include media(lg) {
    padding: 0 map-unit( $header-container-padding, lg );
  }
  @include widthWrapper( $header-container-width, $header-container-padding );
}

.header-container {
  margin: 0 auto;
  @include widthContainer( $header-container-width );
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-unit( $height-header, sm, rem );
  @include media(md) {
    height: map-unit( $height-header, md, rem );
  }
  @include media(lg) {
    height: map-unit( $height-header, lg, rem );
  }

  .header-inner-left {

    .header-title {
      font-size: 1em;
      font-weight: bold;
      @include media(md) {
        font-size: 1em;
      }
      @include media(lg) {
        font-size: 1em;
      }
      a {
        color: $body-text-color;
      }
    }
  }

  .header-inner-right {
    display: flex;
    align-items: center;
    a {
      color: #fff;
      display: block;
      font-weight: bold;
      margin-left: 2em;
    }
  }
}

@charset 'UTF-8';

// 隣接する要素の余白を設定
// ------------------


// 段落
p + p,
p + pre {
  margin-top: map-get( $paragraph-margin, sm );
  @include media(md) {
    margin-top: map-get( $paragraph-margin, md );
  }
  @include media(lg) {
    margin-top: map-get( $paragraph-margin, lg );
  }
}



// button
/*.button + .button,*/
p + .button, .button + p,
img + .button, .button + img, .img + .button, .button + .img, {
  margin-top: map-get( $button-margin, sm );
  @include media(md) {
    margin-top: map-get( $button-margin, md );
  }
  @include media(lg) {
    margin-top: map-get( $button-margin, lg );
  }
}



// image
p + img, img + p,
.img + .img, p + .img, .img + p {
  margin-top: map-get( $image-margin, sm );
  @include media(md) {
    margin-top: map-get( $image-margin, md );
  }
  @include media(lg) {
    margin-top: map-get( $image-margin, lg );
  }
}



// table
table + table,
p + table, table + p,
.table + .table, p + .table, .table + p {
  margin-top: map-get( $table-margin, sm );
  @include media(md) {
    margin-top: map-get( $table-margin, md );
  }
  @include media(lg) {
    margin-top: map-get( $table-margin, lg );
  }
}



// alert
.alert + .alert,
p + .alert, .alert + p,
.img + .alert, .alert + .img {
  margin-top: map-get( $alert-margin, sm );
  @include media(md) {
    margin-top: map-get( $alert-margin, md );
  }
  @include media(lg) {
    margin-top: map-get( $alert-margin, lg );
  }
}



// block
.block + .block,
p + .block, .block + p,
.img + .block, .block + .img {
  margin-top: map-get( $block-margin, sm );
  @include media(md) {
    margin-top: map-get( $block-margin, md );
  }
  @include media(lg) {
    margin-top: map-get( $block-margin, lg );
  }
}

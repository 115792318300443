@charset 'UTF-8';

$google-fontfamily: 'Lato', 'YakuHanJPs', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;

// TOP PAGE
//
.top-screen-wrapper {
  background: $base-color;
  display: flex;
  align-items: center;
  margin-bottom: 3em;
  width: 100%;
  height: 20rem;
  @include media(md) {
    height: 24rem;
  }
  @include media(lg) {
    height: 32rem;
  }
}

.top-screen-heading {
  color: $white-color;
  margin: 0 auto;
  text-align: center;
  h1 {
    font-family: $google-fontfamily;
    font-size: 3em;
    font-weight: 900;
    @include media(md) {
      font-size: 4em;
    }
    @include media(lg) {
      font-size: 5em;
    }
    span {
      display: inline-block;
      animation-name: colMove;
      animation-duration: 1s;
      animation-delay: .8s;
      animation-timing-function: ease-in;
      animation-iteration-count: 1; // 繰り返し infinite：無限
      animation-fill-mode: forwards; // アニメーション終了時のスタイルがアニメーション終了後にも適用
      opacity: 0;
      @for $i from 2 through 11 {
        &:nth-child(#{$i}) {
          animation-delay: .8 + (($i - 1)/10) + s;
        }
      }
    }
  }
}

.top-card {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba( 0,0,0, .12 );
  padding: 2em;
  height: 100%;

  .sub-heading {
    font-family: $google-fontfamily;
    font-size: 11px;
    font-weight: 300;
    @include media(md) {
      font-size: 12px;
    }
    @include media(lg) {
      font-size: 13px;
    }
  }
  h2 {
    font-family: $google-fontfamily;
    font-size: 20px;
    font-weight: 700;
    margin: .3em 0;
    @include media(md) {
      font-size: 22px;
    }
    @include media(lg) {
      font-size: 24px;
    }
  }
  .top-card-body {
    font-size: .925em;
  }
  a.top-card-link {
    background: $second-color;
    color: #fff;
    display: inline-block;
    line-height: 1.42875 !important;
    text-align: center;
    -webkit-appearance: none;
    margin-top: 1.5em;
    padding: .8em 1em;
    overflow: hidden;
    position: relative;
    transition: transform $transition;
    z-index: 0;
    @include media(md) {
      padding: .675em 1em;
    }
    @include media(lg) {
      padding: .8em 1em;
    }
    span:after {
      font-family: $icon-font-family;
      content: '\f061';
      color: inherit;
      display: inline-block;
      font-weight: 900;
      width: 1.28571429em;
      text-align: center;
      margin-left: .5em;
    }
    &:before {
      content: '';
      background: #34495e;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: transform $transition;
      transform: translateX(-100%);
      z-index: -1;
    }
    &:hover {
      &:before {
        transform: translateX(0%);
      }
    }
  }
}


// SUB PAGE
//
.sub-page-hero {
  margin: map-unit($height-header, sm, rem) 0;
  @include media(md) {
    margin: map-unit($height-header, md, rem) 0;
  }
  @include media(lg) {
    margin: map-unit($height-header, lg, rem) 0;
  }
  padding: 3em 0;

  .sub_page_hero_inner_wrapper {
    display: flex;
    flex-flow: column wrap;
    @include media(md) {
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: space-between;
    }

    .sub_page_hero_inner_upper {
      h1 {
        font-family: $google-fontfamily;
        font-size: 36px;
        font-weight: 300;
        margin-bottom: .2em;
        @include media(md) {
          font-size: 48px;
        }
        @include media(lg) {
          font-size: 56px;
        }
      }
      .sub-page-heading {
        font-family: $google-fontfamily;
        font-size: 16px;
        font-weight: 900;
        margin-bottom: 1em;
        @include media(md) {
          font-size: 20px;
          margin-bottom: 0;
        }
        @include media(lg) {
          font-size: 24px;
        }
      }
    }

    .sub_page_hero_inner_lower {
      display: flex;
      align-items: center;
      text-align: right;
    }
  }

}

@charset 'UTF-8';

// Pagetop
// -------
.pagetop {
  display: none;
  position: fixed;
  bottom: 3.2rem;
  right: 1rem;
  z-index: 999;

  @include media(md) {
    bottom: 4.4rem;
    right: 3rem;
  }
  @include media(lg) {
    bottom: 6.4rem;
    right: 3rem;
  }

  & a {
    display: block;
    background: rgba($base-color,0.9);
    text-align: center;
    color: #fff;
    //line-height: 60px;
    font-size: 14px;
    text-decoration: none;
    transition: background .25s ease-out, color .25s ease-out;
    width: 36px;
    height: 36px;
    padding: .4rem;
    @include media(md) {
      width: 40px;
      height: 40px;
    }
    @include media(lg) {
      width: 40px;
      height: 40px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      border-right: 3px solid #fff;
      border-top: 3px solid #fff;
      transform: rotate(-45deg);
      margin-top: .8em;
      transition: transform .3s ease-out;
      @include media(md) {
        width: 16px;
        height: 16px;
        margin-top: .85em;
      }
      @include media(lg) {
        width: 16px;
        height: 16px;
        margin-top: .85em;
      }
    }
  }
}
